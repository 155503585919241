export function useIsWifiProduct(product?: any) {
	const { eCommerceProduct } = useECommerceProduct(product);

	const isWifiProduct = computed<boolean>(() => {
		const modelName = eCommerceProduct.value?.model?.toLowerCase();
		if (modelName === "wi-fi") return true; // Checking exact model name because name can include wi-fi but also have 4g/5g support

		const candidates = ["wi-fi", "wifi", "wi fi", "gps"];
		const productName = (eCommerceProduct.value?.productName || eCommerceProduct.value?.cleanName)?.toLowerCase();
		return candidates.some((candidate) => productName?.includes(candidate));
	});

	// A wifi-only product cannot be linked to a subscription
	return { isWifiProduct };
}
